import formValidation from 'formvalidation/es6/core/Core';
import Bootstrap3 from 'formvalidation/es6/plugins/Bootstrap3';
import DefaultSubmit from 'formvalidation/es6/plugins/DefaultSubmit';
import SubmitButton from 'formvalidation/es6/plugins/SubmitButton';
import Declarative from 'formvalidation/es6/plugins/Declarative';
import Aria from 'formvalidation/es6/plugins/Aria';

(function onDocumentReady(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}(() => {
    const fv = formValidation(document.querySelector('#free-trial-form'), {
        plugins: {
            aria: new Aria(),
            bootstrap3: new Bootstrap3(),
            defaultSubmit: new DefaultSubmit(),
            submitButton: new SubmitButton(),
            declarative: new Declarative({
                html5Input: true,
            }),
        },
    }).on('core.form.valid', () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
            {
                event: 'GAEvent',
                eventCategory: 'Forms',
                eventAction: 'Form Submitted Success',
                eventLabel: 'prior-free-trial-form',
            },
        );
        document.querySelector('#free-trial-form button[type="submit"]').disabled = true;
    });
    document.querySelector('#free-trial-form [name=email]').addEventListener('focus', (e) => {
        fv.resetField(e.target.name);
    });

    document.querySelector('#free-trial-form [name=email]').addEventListener('blur', (e) => {
        e.target.value = e.target.value.trim();
    });
}));
